var config = {};

config.k8sDashboard = process.env.REACT_APP_K8S_DASHBOARD || 'xxxxxxxxx'
config.sampleChartsDashboard = process.env.REACT_APP_SAMPLE_CHARTS_DASHBOARD || 'xxxxxxxxx'
config.otelDashboard = process.env.REACT_APP_OTEL_DASHBOARD || 'xxxxxxxxx';
config.orgId = process.env.REACT_APP_ORGID || 'xxxxxxxxxxx';
config.realm = process.env.REACT_APP_REALM || 'eu0';
config.splunkHECToken = process.env.REACT_APP_SPLUNKHECTOKEN || '00000000-0000-0000-0000-00000000000A';
config.splunkCloudStack = process.env.REACT_APP_SPLUNKCLOUDSTACK || 'acme-o11y-team.stg.splunkcloud.com';
config.splunkObservabilityAdminToken = process.env.REACT_APP_SPLUNKOBSERVABILITY_ADMIN_TOKEN || 'xxx';
config.splunkObservabilityIngestToken = process.env.REACT_APP_SPLUNKOBSERVABILITY_INGEST_TOKEN || 'xxx';

export default config;