import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Alert, Button, Col, Container, Form, Nav, Navbar, Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import config from './config.js';

function App() {

  // Get token and realm from configuration file
  const k8sDashboard = config.k8sDashboard;
  const sampleChartsDashboard = config.sampleChartsDashboard;
  const otelDashboard = config.otelDashboard;
  const splunkHECToken = config.splunkHECToken;
  const splunkCloudStack = config.splunkCloudStack;
  const orgId = config.orgId;
  const realm = config.realm;
  const apiUrl = `https://api.${realm}.signalfx.com/v2`;

  const isPageLoaded = useRef(false);
  const [randomUser, setRandomUser] = useState(null);
  const [selectedSplunkIndex, setSelectedSplunkIndex] = useState('');
  const [selectedEnvironment, setSelectedEnvironment] = useState('');
  const [isLoggingChecked, setIsLoggingChecked] = useState(false); 
  const [isMetricsChecked, setIsMetricsChecked] = useState(false); 
  const [isTracesChecked, setIsTracesChecked] = useState(false); 
  const [appName, setAppName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [k8sClonedDashboard, setK8sClonedDashboard] = useState('');
  const [sampleChartsClonedDashboard, setSampleChartsClonedDashboard] = useState('');
  const [otelClonedDashboard, setOtelClonedDashboard] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  
  const splunkIndexes = [
      { "id": 1, "name": "main", "description": "Mobile app data"},
      { "id": 2, "name": "pixies", "description": "General data"},
      { "id": 3, "name": "roosters", "description": "Marketing data"},
      { "id": 4, "name": "samba", "description": "Payments data"},
      { "id": 5, "name": "vikings", "description": "Business data"},
      { "id": 6, "name": "wolfpack", "description": "Finance data"},
      { "id": 7, "name": "wombat", "description": "HR data"}
    ]

  const sendPostRequest = async (path, data) => {
    try {
      const dataWithUrl = {url: `${apiUrl}/${path}`, data: data};
      const response = await axios.post(`/api`, dataWithUrl);
      return response.data; // Return the response data if needed
    } catch (error) {
      // Handle the error
      if (error.response.data.message) {
        setErrorMessage(`${error.response.status} ${error.response.data.message}`);
      } else if (error.response.data) {
        setErrorMessage(`${error.response.status} ${error.response.data}`);
      } else {
        setErrorMessage("Error: " + error);
      }
    }
  };

  // Get user randomly from users list
  useEffect(() => {
    const users = [
      { "id": 1, "name": "Patrick", "role": "Developer", "team": "Wombat" },
      { "id": 2, "name": "Rachel", "role": "SRE", "team": "Pixies"},
      { "id": 3, "name": "Robert", "role": "DevOps", "team": "Vikings"},
      { "id": 4, "name": "Ian", "role": "Head of Engineering", "team": "Samba"},
      { "id": 5, "name": "Robbie", "role": "SRE", "team": "Wolfpack"},
      { "id": 6, "name": "Fernando", "role": "Evangelist", "team": "Roosters"}
    ];

    if (!isPageLoaded.current) {
      const randomIndex = Math.floor(Math.random() * users.length);
      setRandomUser(users[randomIndex]);
      isPageLoaded.current = true;
    }
  }, []);

  // Submit form to Splunk Observability Cloud
  const [formData, setFormData] = useState({
    authScopes: ['API', 'INGEST'],
    description: '',
    disabled: false,
    name: '',
    permissions: {
      acl: [
        {
          actions: ['READ', 'WRITE'],
          // principalId = who can see the token
          principalId: orgId,
          // principalType can be 'USER', 'TEAM' or 'ORG'
          principalType: 'ORG',
        },
      ],
    },
  });

  const [apiResponse, setApiResponse] = useState(null);

  const handleSubmit = async (event, urlPath) => {
    event.preventDefault();
    setFormData(formData.name = randomUser.team.toLowerCase());

    try {
      const data = await sendPostRequest(urlPath, formData);
      setApiResponse(data);
    } catch (error) {
      if (error.response.data.message) {
        setErrorMessage("Status code " + error.response.status + " - " + error.response.data.message);
      } else {
        setErrorMessage("Status code " + error.response.status + " - " + error.response.data);
      }
    }
  };

  //Creates a new dashboard group and clones a few dashboards
  const dashboardHelper = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const createDashboardGroup = await sendPostRequest(`dashboardgroup`, {"name": randomUser.team});
      const dashboardId = createDashboardGroup.id;

      const clonedOtelDashboard = await sendPostRequest(`dashboardgroup/${dashboardId}/dashboard`, {"sourceDashboard": otelDashboard});
      setOtelClonedDashboard(clonedOtelDashboard.id);

      const clonedK8sDashboard = await sendPostRequest(`dashboardgroup/${dashboardId}/dashboard`, {"sourceDashboard": k8sDashboard});
      setK8sClonedDashboard(clonedK8sDashboard.id);

      const clonedSampleChartsDashboard = await sendPostRequest(`dashboardgroup/${dashboardId}/dashboard`, {"sourceDashboard": sampleChartsDashboard});
      setSampleChartsClonedDashboard(clonedSampleChartsDashboard.id);
      setIsLoading(false);

    } catch (error) {
      if (error.response.data.message) {
        setErrorMessage(`${error.response.status} ${error.response.data.message}`);
      } else if (error.response.data) {
        setErrorMessage(`${error.response.status} ${error.response.data}`);
      } else {
        setErrorMessage("Error: " + error);
      }
    }
  }

  return (    
    <Container>
      <Navbar expand="lg" className="bg-primary rounded-2 navbar-dark">
        <Container>
          <Navbar.Brand href="#">
            <img src="logo.svg" className="svg" alt=""/><b>Acme's Self-Service Portal</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="http://o11y.team:8080" target="_blank" rel="noreferrer">Shop</Nav.Link>
                <Nav.Link href={`https://app.${realm}.signalfx.com`} target="_blank" rel="noreferrer">Splunk Observability Cloud</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>

      {isFormSubmitted ? null : (
        <Form>
          {randomUser && randomUser.name && (
            <Alert variant="info" className="mb-3 mt-3">
              Hi <b>{randomUser.name}</b>, your role is <b>{randomUser.role}</b> and you're in team <b>{randomUser.team}</b>.
            </Alert>
          )}
          <Form.Label className="mb-3">
            <p>The purpose of this self-service portal is to show how easy it is for an SRE or Developer to get telemetry data (logs, metrics, traces) from their application into Splunk Observability Cloud.</p>
            <p>This request form will provide users with a single personalised command to send their data via OpenTelemetry to Splunk Observability Cloud, using a token and setting up automatic team dashboards so data can be viewed in minutes.</p>
          </Form.Label>
          <Row>
            <Col><hr></hr></Col>
            <Col></Col>
          </Row>
          <Form.Group as={Row} className="mb-3" controlId="applicationName">
            <Form.Label column sm="2">Application name</Form.Label>
            <Col sm={10} lg={4}>
              <Form.Control type="input" placeholder="Used to generate cluster name" value={appName} onChange={(e) => { setAppName(e.target.value)}} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="environment">
            <Form.Label column sm="2">Deployment environment</Form.Label>
            <Col sm={10} lg={1}>
              <Form.Select value={selectedEnvironment} onChange={(e) => { setSelectedEnvironment(e.target.value); }}>
                <option></option>
                <option value="dev">dev</option>
                <option value="prod">prod</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="2">What data do you want to send?</Form.Label>
            <Col sm={10}>
              <Form.Check type="checkbox" name="sendData" id="checkboxLogs" label="Logs" checked={isLoggingChecked} onChange={(e) => setIsLoggingChecked(e.target.checked) }/>
              <Form.Check type="checkbox" name="sendData" id="checkboxMetrics" label="Metrics" checked={isMetricsChecked} onChange={(e) => setIsMetricsChecked(e.target.checked) }/>
              <Form.Check type="checkbox" name="sendData" id="checkboxTraces" label="Traces" checked={isTracesChecked} onChange={(e) => setIsTracesChecked(e.target.checked) }/>
            </Col>
          </Form.Group>

        {isLoggingChecked && (
          <Form.Group as={Row} className="mb-3" controlId="splunkIndex">
            <Form.Label column sm="2">Splunk Index</Form.Label>
            <Col sm={10} lg={2}>
              <Form.Select value={selectedSplunkIndex} onChange={(e) => { setSelectedSplunkIndex(e.target.value);}}>
                <option></option>
                {splunkIndexes.map((index) => ( <option key={index.id} value={index.name}> {index.name} </option> ))}
              </Form.Select>
            </Col>
          </Form.Group>
        )}
        {(!buttonEnabled) && appName && selectedEnvironment && (selectedSplunkIndex || isMetricsChecked || isTracesChecked) && (
          setButtonEnabled(true)
        )}
        <Row>
          <Col><hr></hr></Col>
          <Col></Col>
        </Row>
          <Button type="button" disabled={!buttonEnabled} onClick={(event) => { setIsFormSubmitted(true); handleSubmit(event, "token"); }}>Let's go!</Button>

        </Form>
      )}
      {errorMessage && (
        <Alert variant="danger" className="mb-3 mt-3">
          Error: {errorMessage} <Alert.Link href={window.location.href}>Try again.</Alert.Link>
        </Alert>
        )}

      {apiResponse && apiResponse.secret && (
        <Row>
          <Alert variant="success" className="mb-3 mt-3">Token created successfully, you're good to go!</Alert>

          <div>
            <h2>Getting started</h2>
            <ol>
              <li>Copy the code below into your application to get started</li>
              <pre>
            {
`helm repo add splunk-otel-collector-chart https://signalfx.github.io/splunk-otel-collector-chart
helm repo update
helm install splunk-otel-collector \\`}
{isLoggingChecked && (`
--set=splunkPlatform.endpoint=https://http-inputs-${splunkCloudStack}/services/collector \\
--set=splunkPlatform.token=${splunkHECToken} \\
--set=splunkPlatform.index=${selectedSplunkIndex} \\
--set=splunkPlatform.insecureSkipVerify=true \\`
)}{
`
--set=splunkObservability.realm=${realm} \\
--set=splunkObservability.accessToken=${JSON.parse(JSON.stringify(apiResponse.secret))} \\
--set=logsEngine=otel \\
--set=splunkObservability.profilingEnabled=true \\
--set=clusterName=${randomUser.team.toLowerCase()}-${appName}-${selectedEnvironment} \\
--set=environment=${appName}-apm-${selectedEnvironment} \\
splunk-otel-collector-chart/splunk-otel-collector`
            }
          </pre>
              <li>Access Splunk Observability Cloud <a href={`https://app.${realm}.signalfx.com`} target="_blank" rel="noreferrer">here</a>.</li>
              <li>Everything can be used 'as-code', check out the <a href={`https://registry.terraform.io/providers/splunk-terraform/signalfx/latest/docs`} target="_blank" rel="noreferrer">SignalFx terraform provider</a> for example.</li>
              <li>Splunk's documentation for users can be found <a href="https://docs.splunk.com/Observability/index.html" target="_blank" rel="noreferrer">here</a> or <a href="https://dev.splunk.com/Observability/index.html" target="_blank" rel="noreferrer">here</a> for devs.</li>
            </ol>
          </div>
          <div>
            <p><b>Well, given everything's as code... Why not provision some dashboards to get started quickly?</b></p>
            <p><Button type="button" onClick={(event) => { dashboardHelper(event); }} disabled={isLoading}>{isLoading ? "Provisioning..." : "Provision dashboards"}</Button></p>
              {k8sClonedDashboard && (
                  <Alert variant="success" className="mb-3 mt-3">
                    <ul>
                      <li><Alert.Link href={`https://app.${realm}.signalfx.com/#/infra/entity/k8snodes`} target="_blank" rel="noreferrer">Kubernetes Nodes dashboard</Alert.Link></li>
                      <li><Alert.Link href={`https://app.${realm}.signalfx.com/#/dashboard/${k8sClonedDashboard}`} target="_blank" rel="noreferrer">Kubernetes Containers Dashboard</Alert.Link></li>
                      <li><Alert.Link href={`https://app.${realm}.signalfx.com/#/dashboard/${otelClonedDashboard}`} target="_blank" rel="noreferrer">OpenTelemetry Dashboard</Alert.Link></li>
                      <li><Alert.Link href={`https://app.${realm}.signalfx.com/#/dashboard/${sampleChartsClonedDashboard}`} target="_blank" rel="noreferrer">Sample Charts dashboard</Alert.Link></li>
                    </ul>
                  </Alert>
                )}
          </div>
        </Row>
      )}
      </Container>
  );
  
}

export default App;